import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, ButtonGroup, Col, Container, Form, Navbar, Nav, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
// import '@uppy/core/dist/style.css';
// import '@uppy/dashboard/dist/style.css';
// import '@uppy/webcam/dist/style.css';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import GoldenRetriever from '@uppy/golden-retriever';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faVideo, faImage, faFolder, faCamera } from '@fortawesome/free-regular-svg-icons';
import { faVideo, faImage, faFolder, faCamera } from '@fortawesome/free-solid-svg-icons';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";

function UploadMedia(props) {
    const { hash } = useParams();

    const { token, setToken } = useToken();
    const [selected, setSelected] = useState(props.selected);
    const [error, setError] = useState();

    if (error) {
        switch (error) {
            case '403':
                return <Navigate to={`/${hash}/auth`} />;
            default:
        }
    }

    return token ? (
        <div className="position-absolute top-50 start-50 translate-middle" style={{ width: '80vw' }}>
            {selected ? (
                <>
                    {selected == 'camera' ?
                        (
                            <Navigate to={`/${hash}/camera`} />
                        ) :
                        ''}
                    {selected == 'folder' ?
                        (
                            <Navigate to={`/${hash}/folder`} />
                        ) :
                        ''}
                </>
            ) : (
                <Row className="text-center d-flex" >
                    <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '10vh', display: 'flex' }}>
                        <h4 className="mx-auto my-auto">Make a Memento</h4>
                    </Col>
                    <Col className="text-center" sm={12} md={12} lg={12} xl={6} xxl={6} style={{ height: '25vh', display: 'flex' }}>
                        <a href={`/#/${hash}/camera`} onClick={e => {
                            e.preventDefault();
                            setSelected('camera');
                        }} className="mx-auto my-auto">
                            <Button variant="outline-primary" style={{ borderWidth: '0px' }}>
                                <p><FontAwesomeIcon icon={faCamera} size="8x" style={{ maxWidth: '25vw', maxHeight: '20vh' }} /></p>
                                <p><strong>Launch Camera</strong></p>
                            </Button>
                        </a>
                    </Col>
                    {/* <Col sm={12} md={0} lg={0} xl={0} xxl={0} style={{ display: 'flex' }}>
                        <hr className="hr w-100 my-auto" />
                    </Col> */}
                    <Col className="text-center" sm={12} md={12} lg={12} xl={6} xxl={6} style={{ height: '25vh', display: 'flex' }}>
                        <a href={`/#/${hash}/folder`} onClick={e => {
                            e.preventDefault();
                            setSelected('folder');
                        }} className="mx-auto my-auto">
                            <Button variant="outline-primary" style={{ borderWidth: '0px' }}>
                                <p><FontAwesomeIcon icon={faFolder} size='8x' style={{ maxWidth: '25vw', maxHeight: '20vh' }} /></p>
                                <p><strong>Pick from my Device</strong></p>
                            </Button>
                        </a>
                    </Col>
                </Row>
            )}
        </div>
    ) : (
        <Navigate to={`/${hash}/auth`} />
    );
}

export default UploadMedia;
