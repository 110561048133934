import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, Container, Form, Navbar, Nav, Offcanvas, Tab, Tabs } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import GoldenRetriever from '@uppy/golden-retriever';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";
import "./dashboard.scss";

function UploadReceipt() {
    const { hash } = useParams();

    const { token, setToken } = useToken();
    const [redirectNext, setRedirectNext] = useState();
    const [error, setError] = useState();

    const uppyReceipt = useMemo(() => {
        const _uppyReceipt = new Uppy({
            debug: true,
            autoProceed: false,
        })
            .use(XHRUpload, {
                endpoint: `${apiUrl}/receipt/${token}/${hash}`,
            })
            .use(Webcam, {
                showVideoSourceDropdown: true,
                modes: [
                    'picture',
                ],
                showRecordingLength: true,
                countdown: false,
                title: 'Camera (picture)',
                videoConstraints: {
                    facingMode: 'environment',
                    width: { min: 640, ideal: 4032 },
                    height: { min: 480, ideal: 3024 },
                },
            });

        _uppyReceipt.on('complete', (result) => {
            setRedirectNext(true);
            // setTimeout(() => window.location.reload(), 500);
        });

        _uppyReceipt.on('upload-error', (file, error, response) => {
            setError('403');
        });

        _uppyReceipt.use(GoldenRetriever, { serviceWorker: true });

        return _uppyReceipt;
    }, []);

    if (error) {
        switch (error) {
            case '403':
                return <Navigate to={`/${hash}/auth`} />;
            default:
        }
    }

    const dashboardReceiptOptions = {
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        width: '100vw',
        height: '80vh',
        inline: true,
        locale: {
            strings: {
                dropPasteImportFiles: 'Make a Memento',
            },
        },
    };

    return token ? (redirectNext ? (
        <Navigate to={`/${hash}/settings`} />
    ) : (
        <div className="position-absolute top-50 start-50 translate-middle" style={{ height: '80vh', width: '100vw' }}>
            <Dashboard uppy={uppyReceipt} plugins={['Webcam']} {...dashboardReceiptOptions} />
        </div>
    )) : (
        <Navigate to={`/${hash}/auth`} />
    );
}

export default UploadReceipt;
