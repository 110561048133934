import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Navbar, Nav, Offcanvas, Table, FormControl, InputGroup } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faImage, faFolder, faPencil, faTrashCan, faFileVideo, faCloudArrowUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";

async function generateQRs(token, title, quantity, company) {
    return fetch(`${apiUrl}/admin/hashes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ title, quantity, company }),
    })
        .then(data => data.json());
}

async function getGroups(token) {
    return fetch(`${apiUrl}/admin/groups`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function updateGroup(token, groupId, data) {
    return fetch(`${apiUrl}/admin/groups/${groupId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function getCompanies(token) {
    return fetch(`${apiUrl}/admin/companies`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createCompany(token, title) {
    return fetch(`${apiUrl}/admin/companies`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ title }),
    })
        .then(data => data.json());
}

async function uploadVideo(token, groupId, videoFile) {
    const formData = new FormData();
    formData.append("file", videoFile);

    return fetch(`${apiUrl}/admin/groups/${groupId}/upload`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: formData,
    })
        .then(data => data.json());
}

function Group({ item, uploadVideoFile, companies, onCompanyCreate, onUpdateGroup }) {
    const { id, title, tags, video_link, created, company_id, company_title } = item;

    const [currentTitle, setCurrentTitle] = useState(title);
    const [currentCompany, setCurrentCompany] = useState({ value: company_id, label: company_title });
    const [isLoading, setIsLoading] = useState();

    return <tr key={`${id}_group`} id={`${id}_group`}>
        <td>
            <div className="d-flex">
                <Form.Control type="text" size="sm" placeholder={created} className="mx-1 my-auto fw-bold"
                    value={currentTitle}
                    onChange={e => {
                        setCurrentTitle(e.target.value);
                    }} />
                <Button variant="outline-secondary" size="sm" className="mx-1 my-auto"
                    title="Save Group Title" disabled={!currentTitle || title == currentTitle}
                    onClick={async e => onUpdateGroup(id, { title: currentTitle })}>
                    <FontAwesomeIcon icon={faCheck} className="fa-fw" />
                </Button>
            </div>
        </td>
        <td>{created}</td>
        <td>{tags}</td>
        <td>
            {id ?
                <a href={`${apiUrl}/group/${id}/download`} download target="_blank">Download</a> :
                ''}
        </td>
        <td>
            <div className="d-flex">
                <FormControl type="file" className="d-none" id={`file_${id}`} onChange={(e) => uploadVideoFile(e.target.files && e.target.files[0], id)} />
                <Button variant="outline-secondary" className="mx-1 my-1 px-2 py-1"
                    title="Upload Video"
                    onClick={(e) => document.getElementById(`file_${id}`).click()}>
                    <FontAwesomeIcon icon={faCloudArrowUp} className="fa-fw" />
                </Button>
                {video_link ? (
                    <a href={video_link} download target="_blank">
                        <Button variant="outline-secondary" className="mx-1 my-1 px-2 py-1"
                            title="Play/Download Video">
                            <FontAwesomeIcon icon={faVideo} />
                        </Button>
                    </a>
                ) : ''}
                {video_link ? (
                    <Button variant="outline-secondary" className="mx-1 my-1 px-2 py-1"
                        title="Remove Video"
                        disabled>
                        <FontAwesomeIcon icon={faTrashCan} className="fa-fw" />
                    </Button>
                ) : ''}
            </div>
        </td>
        <td>
            <div className="d-flex mx-0">
                {/* <Select
                    key={`${id}_company`}
                    value={currentCompany || { value: company_id, label: company_title }}
                    onChange={c => setCurrentCompany(c)}
                    size="sm"
                    options={companies.map(c => {
                        return { value: c.id, label: c.title };
                    })}
                    className="mx-1 my-auto"
                /> */}
                <CreatableSelect
                    key={`${id}_company`}
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={c => setCurrentCompany(c)}
                    onCreateOption={companyTitle => onCompanyCreate(companyTitle, setCurrentCompany)}
                    options={companies.map(c => {
                        return { value: c.id, label: c.title };
                    })}
                    value={currentCompany || { value: company_id, label: company_title }}
                    className="mx-1 my-auto form-control"
                    size="sm"
                />
                <Button variant="outline-secondary" size="sm" className="mx-1 my-auto"
                    title="Save Company"
                    disabled={!currentCompany || company_id == currentCompany.value}
                    onClick={async e => onUpdateGroup(id, { company: currentCompany.value })}
                >
                    <FontAwesomeIcon icon={faCheck} className="fa-fw" />
                </Button>
            </div>
        </td>
    </tr>
}

function AdminPanel() {
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [quantity, setQuantity] = useState(1);
    const [title, setTitle] = useState();
    const [company, setCompany] = useState();
    const [generated, setGenerated] = useState();
    const [groups, setGroups] = useState();
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        if (token) {
            getGroups(token).then(groups => groups && groups.groups ? setGroups(groups.groups) : false);
            getCompanies(token).then(companies => companies && companies.companies ? setCompanies(companies.companies) : false);
        }
    }, []);

    const onGenerate = (e) => {
        e.preventDefault();
        generateQRs(token, title, quantity, company.value).then(r => {
            if (!r.status) {
                console.log(r);
                setGenerated(r);

                return false;
            }
            getGroups(token).then(_groups => _groups && _groups.groups ? setGroups(_groups.groups) : false);
            getCompanies(token).then(_companies => _companies && _companies.companies ? setCompanies(_companies.companies) : false);
            setTitle('');
            setQuantity(1);
            setCompany();
            setGenerated(r.status);
        });
    };

    const uploadVideoFile = (videoFile, groupId) => {
        if (videoFile) {
            uploadVideo(token, groupId, videoFile).then(r => {
                if (!r.status) {
                    console.log(r);
                }
                getGroups(token).then(groups => groups && groups.groups ? setGroups(groups.groups) : false);
            });
        }
    }

    const onCompanyCreate = (companyTitle, updateCurrentCompany) => {
        setIsLoading(true);
        createCompany(token, companyTitle).then(r => {
            if (!r.company) {
                console.log(r);
                setIsLoading(false);

                return false;
            }

            const newCompany = { value: r.company.id, label: r.company.title };
            setCompanies((companies) => [...companies, r.company]);
            updateCurrentCompany(newCompany);
            setIsLoading(false);
        });
    };

    const onUpdateGroup = (groupId, data) => {
        updateGroup(token, groupId, data).then(r => {
            if (!r.group) {
                console.log(r);
                setGenerated(r);

                return false;
            }
            getGroups(token).then(_groups => _groups && _groups.groups ? setGroups(_groups.groups) : false);
        });
    };

    return user && user.is_admin ? (
        <Container className="mx-auto mt-5">
            {/* <img src="/712.jpg" className="mx-auto mb-0 mt-auto" style={{ maxWidth: '80vw', maxHeight: '20vh', display: 'flex' }} /> */}
            <h3 style={{ display: 'flex' }} className="mx-auto my-2 text-center">Admin panel</h3>
            <Form onSubmit={onGenerate} className="mx-auto">
                <Form.Group className="row my-3">
                    {generated ? <Alert>{generated}</Alert> : ''}
                </Form.Group>
                <Form.Group className="row mt-3 mb-0">
                    <Col>
                        <Form.Label><h4>Create Group and Tags</h4></Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="row mt-3 mb-0">
                    <Col>
                        <Form.Label><h5>Group Title:</h5></Form.Label>
                        <Form.Control type="text" name="title" id="title" key="title" value={title} onChange={e => setTitle(e.target.value)} size="sm" />
                    </Col>
                    <Col>
                        <Form.Label><h5>Number of new Tags:</h5></Form.Label>
                        <Form.Control type="number" name="quantity" id="quantity" key="quantity" value={quantity} onChange={e => setQuantity(e.target.value)} size="sm" />
                    </Col>
                    <Col>
                        <Form.Label><h5>Company:</h5></Form.Label>
                        {/* <Form.Control type="text" name="company" id="company" key="company" value={company} onChange={e => setCompany(e.target.value)} size="sm" /> */}
                        <CreatableSelect
                            isClearable
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            onChange={c => setCompany(c)}
                            onCreateOption={companyTitle => onCompanyCreate(companyTitle, setCompany)}
                            options={companies.map(c => {
                                return { value: c.id, label: c.title };
                            })}
                            value={company}
                        />
                        {/* <Select
                            name="company" id="company" key="company"
                            value={company}
                            onChange={c => setCompany(c)} size="sm"
                            options={companies.map(c => {
                                return { value: c.id, label: c.title };
                            })}
                        /> */}
                    </Col>
                </Form.Group>
                <Form.Group className="row mt-3 mb-2">
                    <Col>
                        <Button variant="outline-primary" className="mx-auto w-100"
                            onClick={onGenerate}
                        >Create Group and Generate Tags</Button>
                    </Col>
                </Form.Group>
                <Form.Group className="row mt-5 mb-2">
                    <Col>
                        {groups && groups.length ? (<Form.Label><h4>Groups</h4></Form.Label>) : ''}
                        {groups && groups.length ? (
                            <Table striped bordered hover size="sm" className="align-middle">
                                <thead>
                                    <tr key="groupsHeader">
                                        <th key="groupTitle">Title</th>
                                        <th key="groupCreated">Created</th>
                                        <th key="groupCount">Count</th>
                                        <th key="groupCSV">CSV</th>
                                        <th key="groupMedia">Media</th>
                                        <th key="groupCompany">Company</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups.map(item => (
                                        <Group
                                            item={item} uploadVideoFile={uploadVideoFile}
                                            key={item.group_id} companies={companies}
                                            onCompanyCreate={onCompanyCreate}
                                            onUpdateGroup={onUpdateGroup}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        ) : ''}
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    ) : (
        <Navigate replace to={`/admin`} />
    );
};

export default AdminPanel;
