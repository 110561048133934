import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, Container, Form, Navbar, Nav, Offcanvas } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";

function Home() {
    // return window.location.replace('https://mementopaperco.com/');
    return (
        <Container>
            <div className="position-absolute top-50 start-50 translate-middle">
                <img src="/712.jpg" style={{ minWidth: '60vw', maxWidth: '80vw' }} />
            </div>
        </Container>
    );
};

export default Home;
