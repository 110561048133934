import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
// import GoldenRetriever from '@uppy/golden-retriever';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/lux/bootstrap.min.css';

// import { apiUrl } from "../../utils/api";
import useToken from './useToken';
import useUser from './useUser';
import Auth from "../Auth/Auth";
import AdminAuth from "../Admin/AdminAuth";
import AdminPanel from "../Admin/AdminPanel";
import Home from "../Home/Home";
import UploadMedia from "../Media/UploadMedia";
import Assign from "../Assign/Assign";
// import UploadVideo from "../Media/UploadVideo";
// import UploadImage from "../Media/UploadImage";
import UploadMediaCamera from "../Media/UploadMediaCamera";
import UploadMediaFolder from "../Media/UploadMediaFolder";
import UploadSettings from "../Media/UploadSettings";
import UploadReceipt from "../Media/UploadReceipt";
import UploadGoLiveDate from "../Media/UploadGoLiveDate";
import Status from "../Status/Status";
import Tag from "../Tag/Tag";
import Diag from "../Diag/Diag";
import Help from "../Help/Help";
import './App.css';

function App() {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();

  return (
    // <Container>
    <HashRouter>
      {/* <Navbar expand="md" fixed="top" className="shadow-sm">
          <Container>
            <Navbar.Brand href="/">
              <img src="/192.png" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <i className="fa-solid fa-burger"></i>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Link to="/login" className="nav-link link-primary text-primary">
                  Sign In
                </Link>
                <Link to="/register" className="nav-link link-success text-success">
                  Sign Up
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}
      <Routes>
        <Route path="/help" element={<Help />} />
        {/* <Route path="/diag" element={<Diag />} /> */}
        <Route path="/tag/:hash" element={<Tag />} />
        <Route path="/:hash/auth" element={<Auth />} />
        <Route path="/:hash/media" element={<UploadMedia />} />
        <Route path="/:hash/qr" element={<Assign />} />
        {/*
          <Route path="/:hash/video" element={<UploadVideo />} />
          <Route path="/:hash/image" element={<UploadImage />} />
          */}
        <Route path="/:hash/camera" element={<UploadMediaCamera />} />
        <Route path="/:hash/folder" element={<UploadMediaFolder />} />
        <Route path="/:hash/settings" element={<UploadSettings />} />
        <Route path="/:hash/receipt" element={<UploadReceipt />} />
        <Route path="/:hash/golivedate" element={<UploadGoLiveDate />} />
        <Route path="/:hash/status" element={<Status />} />
        <Route path="/:hash" element={<Tag />} />
        <Route path="/admin/panel" element={<AdminPanel />} />
        <Route path="/admin" element={<AdminAuth />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </HashRouter>
    // </Container>
  );
}

export default App;
