import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Navigate, Link } from 'react-router-dom';
import { Button, Col, Container, Form, Navbar, Nav, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faVideo, faImage } from '@fortawesome/free-regular-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from "../../utils/api";
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import Loader from "../Loader/Loader";
// import TagDate from "./TagDate";
import useOrder from '../App/useOrder';

async function getTag(hash) {

    return fetch(`${apiUrl}/tag/${hash}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(r => {
            // if (r.status === 401 || r.status === 403) {
            //     return { error: r.status };
            // }

            return r.json();
        });
}

async function assignOrderHash(order, hash) {
    return fetch(`${apiUrl}/assign`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ order, hash }),
    })
        .then(data => data.json());
}

function Tag() {
    const { hash } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [screen, setScreen] = useState();
    const [tagHash, setTagHash] = useState(hash);
    const [tagContents, setTagContents] = useState({});
    const [loading, setLoading] = useState(true);
    const [playButton, setPlayButton] = useState(false);
    const { order, setOrder } = useOrder();

    useEffect(() => {
        if (hash) {
            if (tagContents && tagContents.status) {
                window.location.reload();
            }
            setTagContents({});
            loadTag(hash);
        }
    }, [hash]);

    const loadTag = (hash) => {
        function processTag(r) {
            if (!r.tag || !r.tag.id) {
                console.log(r);
            }
            setTagContents(r.tag);
            setTimeout(() => {
                if (r.tag.video_link) {
                    if (r.tag.check && r.tag.check.state == 'ready') {
                        setPlayButton(true);
                    } else if (r.tag.check && r.tag.check.state != 'ready') {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            }, 5000);
        };

        if (order) {
            assignOrderHash(order, hash).then(r => {
                setOrder(null);
                // processTag(r);
                setLoading(false);
            });
        } else {
            getTag(hash).then(r => {
                processTag(r);
            });
        }
    };

    if (!loading && tagContents && tagContents.golive_date) {
        const now = new Date();
        const goLiveDate = new Date(tagContents.golive_date);

        if (goLiveDate > now) {
            return (
                <Container style={{ height: '100vh', width: '100vw', maxWidth: '100vw', display: 'flex', flexDirection: 'column' }}>
                    <Loader goLiveDate={goLiveDate.toLocaleDateString()} />
                </Container>
            );
        }
    }

    if (loading) {
        return (
            <Container style={{ height: '100vh', width: '100vw', maxWidth: '100vw', display: 'flex', flexDirection: 'column' }}>
                <Loader playButton={playButton} setLoading={setLoading} />
            </Container>
        );
    }

    if (tagContents && tagContents.status == 'active') {

        if (tagContents.video_link && (screen == 'video' || !screen)) {
            return (
                <Container style={{ height: '100vh', width: '100vw', maxWidth: '100vw', background: '#000', display: 'flex', flexDirection: 'column' }}>
                    {tagContents.check && tagContents.check.state == 'ready' ? (
                        <div style={{ height: '70vh', width: '100vw', display: 'flex' }}>
                            <iframe
                                src={`${tagContents.video_link}?autoplay=true&preload=true`}
                                style={{
                                    border: "none",
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    height: "70vh",
                                    width: "100vw",
                                }}
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowFullScreen={true}>
                            </iframe>
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100vw', display: 'flex', background: '#ebebed' }}>
                            <Button variant="outline-primary" className="mx-auto my-auto" style={{ borderWidth: '0px' }} >
                                <p><FontAwesomeIcon icon={faClock} size='8x' style={{ maxWidth: '25vw', maxHeight: '20vh' }} /></p>
                                <p><strong>Your Memento is now processing and will be available shortly!</strong></p>
                            </Button>
                        </div>
                    )}
                    <div style={{ height: '30vh', width: '100vw', display: 'flex', background: '#ebebed' }}>
                        {/* {tagContents.check && tagContents.check.state == 'ready' ? ( */}
                        <Button onClick={(e) => setScreen(tagContents.image_link ? 'image' : 'shop')} className="my-auto mx-auto w-75">Done</Button>
                        {/* ) : (
                            <Button disabled className="d-flex mx-auto mt-4 mb-auto">Done</Button>
                        )} */}
                    </div>
                </Container>
            );
        }

        if (tagContents.image_link && (screen == 'image' || !screen)) {
            return (
                <Container style={{ height: '100vh', width: '100vw', maxWidth: '100vw', background: '#000', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '70vh', width: '100vw', backgroundColor: '#000', display: 'flex' }}>
                        <img src={tagContents.image_link} style={{ maxHeight: '100vh', maxWidth: '100vw' }} className="mx-auto my-auto" />
                    </div>
                    <div style={{ height: '30vh', width: '100vw', display: 'flex', background: '#ebebed' }}>
                        <Button onClick={(e) => setScreen('shop')} className="d-flex mx-auto mt-4 mb-auto">Done</Button>
                    </div>
                </Container>
            );
        }

        if (screen == 'shop') {
            return (
                <Container style={{ height: '100vh', width: '100vw', maxWidth: '100vw', background: '#000', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '70vh', width: '100vw', backgroundColor: '#ebebed', display: 'flex', flexDirection: 'column' }}>
                        <div className="my-2"></div>
                        {tagContents && tagContents.download_allowed && tagContents.download_link ? (
                            <a href={tagContents.download_link} download className="mx-auto my-auto w-75" target="_blank">
                                <Button className="w-100">Save Video</Button>
                            </a>
                        ) : ''}
                        <Button className="mx-auto my-auto w-75" onClick={(e) => navigator.share({
                            title: 'Hello! Im sharing a Memento Paper Co. message with you. Follow the link below to see the unique Memento:',
                            text: 'Hello! Im sharing a Memento Paper Co. message with you. Follow the link below to see the unique Memento:',
                            url: `https://app.mementopaperco.com/#/tag/${hash}`,
                        })}>Share</Button>
                        <h4 className="mx-auto my-auto w-50 text-center">Want to try MementoPaperCo Tags?</h4>
                        <img src={'//cdn.shopify.com/s/files/1/0688/7997/4696/files/package.jpg?v=1671141486&width=640'} style={{ maxHeight: '40vh', maxWidth: '100vw', aspectRatio: '1 / 1' }} className="mx-auto my-auto" />
                    </div>
                    <div style={{ height: '30vh', width: '100vw', display: 'flex', flexDirection: 'column', background: '#ebebed' }}>
                        <a href="https://mementopaperco.com/collections/all" className="mx-auto mt-4 mb-auto w-75" target="_blank">
                            <Button className="w-100">
                                Shop now
                            </Button>
                        </a>
                    </div>
                </Container>
            );
        }
    }

    return (!tagContents || tagContents.status != 'active') ?
        (
            <Navigate to='/' />
        ) :
        (token ? (
            <Navigate to={`/${hash}/media`} />
        ) : (
            <Navigate to={`/${hash}/auth`} />
        ));
};

export default Tag;
