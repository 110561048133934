import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function UploadModal(props) {
    const { open } = props;

    const [show, setShow] = useState(open);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    setTimeout(() => setShow(false), 2000);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Memento Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do not close this page until your Memento upload completes.
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

export default UploadModal;