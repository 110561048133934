import { useState } from 'react';

export default function useOrder() {
    const getOrder = () => {
        const order = localStorage.getItem('order');

        return order;
    };

    const [order, setOrder] = useState(getOrder());

    const saveOrder = order => {
            localStorage.setItem('order', order);
            setOrder(order);
    };

    return {
        setOrder: saveOrder,
        order,
    }
}