import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, Col, Container, Form, Navbar, Nav, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import GoldenRetriever from '@uppy/golden-retriever';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";

async function updateTag(token, hash, goliveDate) {

    return fetch(`${apiUrl}/tag/${hash}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            golive_date: (goliveDate || null),
        }),
    })
        .then(data => data.json());
}


function UploadGoLiveDate() {
    const { hash } = useParams();

    const { token, setToken } = useToken();
    const [redirectNext, setRedirectNext] = useState();
    const [goLiveDate, setGoLiveDate] = useState();
    const [error, setError] = useState();

    const updateAllowDownload = (token, hash, goLiveDate) => {
        updateTag(token, hash, goLiveDate).then(r => {
            if (!r.tag || !r.tag.id) {
                console.log(r);
                setError('403');
            }
        });
    };

    if (error) {
        switch (error) {
            case '403':
                return <Navigate to={`/${hash}/auth`} />;
            default:
        }
    }

    return token ? (redirectNext ? (
        <Navigate to={`/${hash}/settings`} />
    ) : (
        <div className="position-absolute top-50 start-50 translate-middle">
            <Row className="text-center" style={{ height: '30vh', width: '100vw' }}>
                <Col>
                    <Form.Control type='date' onChange={(e) => {
                        console.log(e.target.value);
                        setGoLiveDate(e.target.value);
                        updateAllowDownload(token, hash, e.target.value);
                    }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ height: '30vh', width: '100vw' }}>
                <Col>
                    <Button variant="primary" onClick={(e) => setRedirectNext('settings')}>
                        Save
                    </Button>
                </Col>
            </Row>
        </div>
    )) : (
        <Navigate to={`/${hash}/auth`} />
    );
}

export default UploadGoLiveDate;
