import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, ButtonGroup, Col, Container, Form, Navbar, Nav, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
// import '@uppy/core/dist/style.css';
// import '@uppy/dashboard/dist/style.css';
// import '@uppy/webcam/dist/style.css';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import GoldenRetriever from '@uppy/golden-retriever';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faVideo, faImage } from '@fortawesome/free-regular-svg-icons';
import { faVideo, faImage } from '@fortawesome/free-solid-svg-icons';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from '../../utils/api';
import UploadModal from './UploadModal';
import "./dashboard.scss";

function UploadMediaCamera() {
    const { hash } = useParams();

    const { token, setToken } = useToken();
    const [redirectNext, setRedirectNext] = useState();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [error, setError] = useState();
    const [selected, setSelected] = useState();

    const uppyVideo = useMemo(() => {
        const _uppyVideo = new Uppy({
            debug: true,
            autoProceed: true,
        })
            .use(Tus, {
                chunkSize: 5242880,
                endpoint: `${apiUrl}/video/${token}/${hash}`,
            })
            .use(Webcam, {
                showVideoSourceDropdown: true,
                modes: [
                    'video-audio',
                ],
                showRecordingLength: true,
                countdown: false,
                title: 'Camera (video)',
                mobileNativeCamera: true,
                videoConstraints: {
                    facingMode: 'user',
                    width: { min: 640, ideal: 1920 },
                    height: { min: 480, ideal: 1080 },
                },
            });

        _uppyVideo.on('complete', (result) => {
            setRedirectNext(true);
            // setTimeout(() => window.location.reload(), 500);
            return (<Navigate to={`/${hash}/settings`} />);
        });

        _uppyVideo.on('upload', (data) => {
            setShowUploadModal(true);
        });

        _uppyVideo.on('upload-error', (file, error, response) => {
            // console.log('error with file:', file.id);
            // console.log('error message:', error);
            // console.log('response:', response);
            // setTimeout(() => window.location.href = `/#/${hash}/auth`, 5000);
            setError('403');
        });

        _uppyVideo.use(GoldenRetriever, { serviceWorker: true });

        return _uppyVideo;
    }, []);

    const dashboardVideoOptions = {
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        width: '100vw',
        height: '80vh',
        inline: true,
        locale: {
            strings: {
                dropPasteImportFiles: 'Make a Memento',
            },
        },
    };

    const uppyImage = useMemo(() => {
        const _uppyImage = new Uppy({
            debug: true,
            autoProceed: false,
        })
            .use(XHRUpload, {
                endpoint: `${apiUrl}/image/${token}/${hash}`,
            })
            .use(Webcam, {
                showVideoSourceDropdown: true,
                modes: [
                    'picture',
                ],
                showRecordingLength: true,
                countdown: false,
                title: 'Camera (picture)',
                videoConstraints: {
                    facingMode: 'user',
                    width: { min: 640, ideal: 4032 },
                    height: { min: 480, ideal: 3024 },
                },
            });

        _uppyImage.on('complete', (result) => {
            setRedirectNext(true);
            // setTimeout(() => window.location.reload(), 500);
            return (<Navigate to={`/${hash}/settings`} />);
        });

        _uppyImage.on('upload-error', (file, error, response) => {
            setError('403');
        });

        _uppyImage.use(GoldenRetriever, { serviceWorker: true });

        return _uppyImage;
    }, []);

    const dashboardImageOptions = {
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        width: '100vw',
        height: '80vh',
        inline: true,
        locale: {
            strings: {
                dropPasteImportFiles: 'Make a Memento',
            },
        },
    };

    if (error) {
        switch (error) {
            case '403':
                return <Navigate to={`/${hash}/auth`} />;
            default:
        }
    }

    if (token && redirectNext) {
        return <Navigate to={`/${hash}/settings`} />;
    }

    if (token && selected == 'video') {
        return (
            <div className="mt-0">
                <Dashboard uppy={uppyVideo} plugins={['Webcam']} {...dashboardVideoOptions} />
                {showUploadModal ? (<UploadModal open={true} />) : ''}
            </div>
        );
    }

    if (token && selected == 'image') {
        return (
            <div className="mt-0">
                <Dashboard uppy={uppyImage} plugins={['Webcam']} {...dashboardImageOptions} />
            </div>
        );
    }

    return token ? (
        <div className="position-absolute top-50 start-50 translate-middle" style={{ width: '80vw' }}>
            <Row className="text-center d-flex" >
                <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '10vh', display: 'flex' }}>
                    <h4 className="mx-auto my-auto">Make a Memento</h4>
                </Col>
                <Col className="text-center" sm={12} md={12} lg={12} xl={6} xxl={6} style={{ height: '25vh', display: 'flex' }}>
                    <a href={`/#/${hash}/video`} onClick={e => {
                        e.preventDefault();
                        setSelected('video');
                    }} className="mx-auto my-auto">
                        <Button variant="outline-primary" style={{ borderWidth: '0px' }}>
                            <p><FontAwesomeIcon icon={faVideo} size="8x" style={{ maxWidth: '25vw', maxHeight: '20vh' }} /></p>
                            <p><strong>Shoot a Video</strong></p>
                        </Button>
                    </a>
                </Col>
                <Col className="text-center" sm={12} md={12} lg={12} xl={6} xxl={6} style={{ height: '25vh', display: 'flex' }}>
                    <a href={`/#/${hash}/image`} onClick={e => {
                        e.preventDefault();
                        setSelected('image');
                    }} className="mx-auto my-auto">
                        <Button variant="outline-primary" style={{ borderWidth: '0px' }}>
                            <p><FontAwesomeIcon icon={faImage} size='8x' style={{ maxWidth: '25vw', maxHeight: '20vh' }} /></p>
                            <p><strong>Take a Photo</strong></p>
                        </Button>
                    </a>
                </Col>
            </Row>
        </div>
    ) : (
        <Navigate to={`/${hash}/auth`} />
    );
}

export default UploadMediaCamera;
