import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, Container, Form, Navbar, Nav, Offcanvas } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";

async function startPhoneVerification(phone) {
    return fetch(`${apiUrl}/admin/phone?k=a5183e992301b24c4ebbd10196640f0c`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(data => data.json());
}

async function finishPhoneVerification(code) {
    return fetch(`${apiUrl}/admin/code?k=a5183e992301b24c4ebbd10196640f0c`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code }),
    })
        .then(data => data.json());
}

function AdminAuth() {
    const { hash } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [sent, setSent] = useState(false);
    const [code, setCode] = useState('');
    const [verified, setVerified] = useState(false);

    const onSubmitPhone = (e) => {
        e.preventDefault();
        startPhoneVerification().then(r => {
            if (!r.status) {
                console.log(r);
            }
            setSent(true);
        });
    };

    const onSubmitCode = (e) => {
        e.preventDefault();
        finishPhoneVerification(code).then(r => {
            if (!r.auth) {
                console.log(r);
            }
            setToken(r.auth || null);
            setUser(r.user || null);
            setVerified(true);
        });
    };

    if (token && user && user.is_admin) {
        return <Navigate replace to={`/admin/panel`} />;
    }

    return sent ?
        (verified ? (
            <Navigate replace to={`/admin/panel`} />
        ) : (
            <Container className="">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div style={{ width: '80vw', height: '40vh', margin: 'auto', display: 'flex' }}>
                        <img src="/712.jpg" className="mx-auto mb-0 mt-auto" style={{ maxWidth: '80vw', maxHeight: '40vh', display: 'flex' }} />
                    </div>
                    <div style={{ margin: 'auto', display: 'flex' }}>
                        <h4 style={{ display: 'flex' }} className="mx-auto my-4 text-center">Enter Verification Code</h4>
                    </div>
                    <div style={{ height: '40vh', margin: 'auto', display: 'flex' }}>
                        <Form onSubmit={onSubmitCode} className="mx-auto">
                            <Form.Group className="row">
                                <Form.Label></Form.Label>
                            </Form.Group>
                            <Form.Group className="row">
                                <Form.Control type="text" name="code" id="code" key="code" value={code} onChange={e => setCode(e.target.value)} size="sm" />
                            </Form.Group>
                            <Form.Group className="mt-2 =row">
                                <Form.Label></Form.Label>
                            </Form.Group>
                            <Form.Group className="row">
                                <Button type="submit">Confirm</Button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Container>
        )) : (
            <Container className="">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div style={{ width: '80vw', height: '40vh', margin: 'auto', display: 'flex' }}>
                        <img src="/712.jpg" className="mx-auto mb-0 mt-auto" style={{ maxWidth: '80vw', maxHeight: '40vh', display: 'flex' }} />
                    </div>
                    <div style={{ margin: 'auto', display: 'flex' }}>
                        <h4 style={{ display: 'flex' }} className="mx-auto my-4">Sign Up & Verify</h4>
                    </div>
                    <div style={{ height: '40vh', margin: 'auto', display: 'flex' }}>
                        <Form onSubmit={onSubmitPhone} className="mx-auto">
                            <Form.Group className="row">
                                <Form.Label></Form.Label>
                            </Form.Group>
                            <Form.Group className="row">
                                <Button type="submit">Send Verification</Button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Container >
        );
};

export default AdminAuth;
