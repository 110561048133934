import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Col, Container, Form, Navbar, Nav, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
import QRCode from "react-qr-code";
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useOrder from '../App/useOrder';
import { apiUrl, isMobile } from '../../utils/api';

function Assign() {
    const { hash } = useParams();

    const { token, setToken } = useToken();
    const [error, setError] = useState();
    const { order, setOrder } = useOrder();

    useEffect(() => {
        if (hash) {
            setOrder(hash);
        }
    }, [hash]);

    if (error) {
        switch (error) {
            case '403':
                return <Navigate to={`/${hash}/auth`} />;
            default:
        }
    }

    return (
        <div className="position-absolute top-50 start-50 translate-middle" style={{ width: '80vw' }}>
            {error ? (
                <Alert>
                    {error}
                </Alert>
            ) : ''}
            {isMobile ?
                (
                    <Row className="text-center d-flex" >
                        <Col className="text-center" sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '10vh', display: 'flex' }}>
                            <h4 className="mx-auto my-auto">{order ? `The customer order has been detected` : ''}</h4>
                        </Col>
                        <Col className="text-center" sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '10vh', display: 'flex' }}>
                            <p><small>{order ? `(ID is ${order})` : ''}</small></p>
                        </Col>
                        <Col className="text-center" sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '30vh', display: 'flex' }}>
                            <h4 className="mx-auto my-auto">{order ? 'Scan the QR code on the tag to link it to this order' : ''}</h4>
                        </Col>
                    </Row>
                ) : (
                    <Row className="text-center d-flex" >
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '10vh', display: 'flex' }}>
                            <h4 className="mx-auto my-auto">Scan the QR Code</h4>
                        </Col>
                        {/* <Col className="text-center" sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '10vh', display: 'flex' }}>
                            <h4 className="mx-auto my-auto">{order ? `Your Order has been detected (ID is ${order})` : ''}</h4>
                        </Col> */}
                        <Col className="text-center" sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '30vh', display: 'flex' }}>
                            <h4 className="mx-auto my-auto">
                                <QRCode
                                    size={256}
                                    // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`https://app.mementopaperco.com/#/${order}/qr`}
                                    viewBox={`0 0 256 256`}
                                />
                            </h4>
                        </Col>
                        <Col className="text-center" sm={12} md={12} lg={12} xl={12} xxl={12} style={{ height: '30vh', display: 'flex' }}>
                            <h4 className="mx-auto my-auto">{order ? 'Scan the QR code on the tag to link it to this order' : ''}</h4>
                        </Col>
                    </Row>
                )
            }
        </div>
    );
}

export default Assign;
