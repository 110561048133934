import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import intlTelInput from 'intl-tel-input';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import GoldenRetriever from '@uppy/golden-retriever';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { apiUrl } from "../../utils/api";

async function getTag(hash) {

    return fetch(`${apiUrl}/tag/${hash}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(r => {
            // if (r.status === 401 || r.status === 403) {
            //     return { error: r.status };
            // }

            return r.json();
        });
}

async function updateTag(token, hash, allowDownload, receiptLink, goliveDate) {

    return fetch(`${apiUrl}/tag/${hash}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            download_allowed: (allowDownload ? '1' : '0'),
            receipt_link: (receiptLink || null),
            golive_date: (goliveDate || null),
        }),
    })
        .then(data => data.json());
}

function UploadSettings() {
    const { hash } = useParams();

    const { token, setToken } = useToken();
    const [tagContents, setTagContents] = useState({});
    const [allowDownload, setAllowDownload] = useState(0);
    const [receiptLink, setReceiptLink] = useState();
    const [goLiveDate, setGoLiveDate] = useState();
    const [redirectNext, setRedirectNext] = useState();
    const [skipUploadReceipt, setSkipUploadReceipt] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if (hash) {
            loadTag(hash);
        }
    }, [hash]);

    const loadTag = (hash) => {
        getTag(hash).then(r => {
            if (!r.tag || !r.tag.id) {
                console.log(r);
            }
            // setTagContents(r.tag);
            setAllowDownload(r.tag.download_allowed);
            setGoLiveDate(r.tag.golive_date);
            setReceiptLink(r.tag.receipt_link);
            // setLoading(false);
        });
    };

    const updateAllowDownload = (token, hash, allowDownload, receiptLink, goLiveDate) => {
        updateTag(token, hash, allowDownload, receiptLink, goLiveDate).then(r => {
            if (!r.tag || !r.tag.id) {
                console.log(r);
                setError('403');
            }
        });
    };

    if (error) {
        switch (error) {
            case '403':
                return <Navigate to={`/${hash}/auth`} />;
            default:
        }
    }

    return token ? (redirectNext ? (
        <Navigate to={`/${hash}/${redirectNext}`} />
    ) : (
        <div className="position-absolute top-50 start-50 translate-middle" style={{ width: '80vw' }}>
            <Row className="text-center" style={{ height: '15vh', display: 'flex' }}>
                <h4 className="mx-auto my-auto">The Last Step: Settings</h4>
            </Row>
            <Row className="text-center" style={{ height: '15vh' }}>
                <Col className="text-start" style={{ display: 'flex' }}>
                    <Form.Label className="mx-auto my-auto">Show Download link</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Form.Label className="ms-auto my-auto" type="text" >{allowDownload ? 'On' : 'Off'}</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Form.Check className="ms-auto my-auto" type="switch" onChange={(e) => {
                        setAllowDownload(e.target.checked);
                        updateAllowDownload(token, hash, e.target.checked);
                    }} checked={allowDownload} style={{
                        fontSize: '1.5rem',
                    }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ height: '15vh' }}>
                <Col className="text-start" style={{ display: 'flex' }}>
                    <Form.Label className="mx-auto my-auto">Add Gift Receipt</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Form.Label className="ms-auto my-auto" type="text" >{receiptLink ? 'On' : 'Off'}</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Form.Check className="ms-auto my-auto" type="switch" onChange={(e) => {
                        setRedirectNext('receipt');
                    }} checked={receiptLink} style={{
                        fontSize: '1.5rem',
                    }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ height: '15vh' }}>
                <Col className="text-start" style={{ display: 'flex' }}>
                    <Form.Label className="mx-auto my-auto">Set a "Go Live Date"</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Form.Label className="ms-auto my-auto" type="text" >{goLiveDate ? 'On' : 'Off'}</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Form.Check className="ms-auto my-auto" type="switch" onChange={(e) => {
                        setRedirectNext('golivedate');
                    }} checked={goLiveDate} style={{
                        fontSize: '1.5rem',
                    }} />
                </Col>
            </Row>
            {/* <Row className="text-center" style={{ height: '15vh' }}>
                <Col style={{ display: 'flex' }}> */}
            <Form.Group className="row">
                <Button variant="primary" onClick={(e) => {
                    setRedirectNext('status');
                }}>Done</Button>
            </Form.Group>
            {/* </Col>
            </Row> */}
        </div>
    )) : (
        <Navigate to={`/${hash}/auth`} />
    );
}

export default UploadSettings;
