import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Col, Button, Form, Spinner, Row } from 'react-bootstrap';

function Loader(props) {

    const { goLiveDate, hash } = props;
    const localGoLiveDate = goLiveDate ? new Date(goLiveDate) : null;
    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [timer, setTimer] = useState(false);
    const [goTag, setGoTag] = useState(false);

    const timeString = () => {
        const now = new Date();
        const distance = localGoLiveDate.getTime() - now.getTime();
        if (distance > 0) {
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

            return `${days} days ${hours} hours and ${minutes} minutes - until you can view this Memento`;
        } else {
            return false;
        }
    }

    if (localGoLiveDate) {
        if (!timer) {
            setTimer(timeString());
        }
        setInterval(() => {
            setTimer(timeString());
        }, 60 * 1000);
    }

    if (goTag) {
        return (<Navigate to={`/${hash}`} />);
    }

    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            <div style={{ width: '80vw', height: '50vh', margin: 'auto', display: 'flex' }}>
                <img src="/712.jpg" className="mx-auto mb-4 mt-auto" style={{ maxWidth: '80vw', maxHeight: '50vh', display: 'flex' }} />
            </div>
            <div style={{ margin: 'auto', height: '20vh' }}>
                {!timer ? (
                    <>
                        {props.playButton ?
                            (
                                <Button className="mx-auto my-auto w-100" onClick={(e) => {
                                    if (props.setLoading) {
                                        props.setLoading(false);
                                    }
                                }}>Play</Button>
                            )
                            : ''
                        }
                        {props.done ?
                            (
                                <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                                    <p className="d-flex mx-auto w-100 pt-0 pb-5 text-center mt-0 mb-4">Your Memento is now processing and will be available shortly!</p>
                                    <Form.Group className="row">
                                        <Button onClick={(e) => setGoTag(true)}>Done</Button>
                                    </Form.Group>
                                </div>
                            )
                            : (!props.playButton ? (
                                <Spinner animation="border" role="status" style={{ margin: 'auto', display: 'flex' }}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : '')
                        }
                    </>
                ) : ''}
            </div>
            {/* <div style={{ height: '10vh', margin: 'auto', display: 'flex' }}>
                <p style={{ display: 'flex' }} className="mx-auto mt-4 mb-auto">MementoPaperCo</p>
            </div> */}
            <div style={{ height: '20vh', margin: 'auto', display: 'flex' }}>
                {localGoLiveDate ? (
                    <h4 style={{ display: 'flex', textAlign: 'center' }} className="mx-auto mt-4 mb-auto">
                        {timer}
                    </h4>
                ) : ''}
            </div>
        </div >
    );
};

export default Loader;
