import React, { useEffect, useState } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { Button, Col, Container, Navbar, Nav, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { apiUrl } from "../../utils/api";
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import Loader from "../Loader/Loader";
import TagDate from "../Tag/TagDate";

async function getTag(hash) {

    return fetch(`${apiUrl}/tag/${hash}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(r => {
            // if (r.status === 401 || r.status === 403) {
            //     return { error: r.status };
            // }

            return r.json();
        });
}

function Status() {
    const { hash } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [screen, setScreen] = useState();
    const [tagHash, setTagHash] = useState(hash);
    const [tagContents, setTagContents] = useState({});
    const [loading, setLoading] = useState(true);
    const [playButton, setPlayButton] = useState(false);

    useEffect(() => {
        if (tagHash) {
            loadTag(tagHash);
        }

        return () => {
            setTagHash();
        }
    }, [tagHash]);

    const loadTag = (hash) => {
        getTag(hash).then(r => {
            if (!r.tag || !r.tag.id) {
                console.log(r);
            }
            setTagContents(r.tag);
            setTimeout(() => {
                if (r.tag.video_link) {
                    setPlayButton(true);
                } else {
                    setLoading(false);
                }
            }, 5000);
        });
    };

    if (!loading && tagContents && tagContents.golive_date) {
        const now = new Date();
        const goLiveDate = new Date(tagContents.golive_date);

        if (goLiveDate > now) {
            return (
                <Container>
                    <Loader goLiveDate={goLiveDate.toLocaleDateString()} />
                </Container>
            );
        }
    }

    if (loading) {
        return (
            <Container>
                <Loader playButton={playButton} setLoading={setLoading} />
            </Container>
        );
    }

    if (tagContents && tagContents.status == 'active') {
        return (
            <Container>
                <Loader done={true} hash={hash} />
            </Container>
        );
    }

    return (!tagContents || tagContents.status != 'active') ?
        (
            <Navigate to='/' />
        ) :
        (token ? (
            <Navigate to={`/${hash}/media`} />
        ) : (
            <Navigate to={`/${hash}/auth`} />
        ));
};

export default Status;
